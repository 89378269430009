
:root {
  //Themes colors
  --color-primary: #FD4766;
  --color-secondary: #337DFF;

  --color-secondary-rgb: rgba(253, 71, 102 ,1);
  --color-tertiary: #010001;
  --color-dark: #000000;
  
  // Typo Color 
  --color-heading: #1f1f25;
  --color-body: #1d1d24;

  // Gery Colors 
  --color-gray: #757575;
  --color-midgray: #e9ecef;
  --color-opacity-1: hsla(0,0%,100%,.2);
  
  --color-light: #E4E6EA;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;
  --color-border: #E6E6E6;
  --color-white: #ffffff;
  
  // Notify Colors 
  --color-success: #3EB75E;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;


  //Social icon colors
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;


  // Extra Color 
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;
  
  //Border-radius
  --radius: 10px;
  --radius-big: 16px;
  --radius-small: 6px;
  --border-width: 2px;

  //Font weight
  //primary font
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;

  //secondary font
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-bold: 700;
  --s-black: 900;

  //Shadows
  --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
  --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);


  //transition easing
  --transition: 0.3s;

  //Font Family
  --font-primary: 'Poppins', sans-serif;
  --secondary-font: 'Poppins', sans-serif;
  --font-awesome: 'Font Awesome 5 Pro';

  
  //Fonts Size
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.67; //28px
  --line-height-b2: 1.5; //24px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px

  
  // Heading Font 
  --h1: 44px; //52px 1.29
  --h2: 36px; //44px 1.23
  --h3: 30px; // 34px 1.14
  --h4: 24px; //30px  1.25
  --h5: 18px; //22px 1.24
  --h6: 16px; //20px 1.25
}







//===== Colors =====//
// $theme-color: #f9004d;
// $theme-color-alt: #ee076e;
$body-color: #1d1d24;
$heading-color: #1f1f25;
$border-color: #d4d4d4;
$black-color: #010001;
$white:#fff;




/*===============================
  Font Family 
=================================*/
$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;


// Heading Font List
$font-1: 'Poppins', sans-serif;
$fontList: $font-1;


/*===============================
  Color Variation 
=================================*/
$color-1:  var(--color-primary);
$color-2:  #f4769a;
$color-3:  #2f21b3;
$color-4:  #8956e2;
$colorList: $color-1, $color-2 ,$color-3 ,$color-4;


//===== Line Height =====//
$body-line-height: 1.714286;

// Layouts
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
/* container 450px*/
$small-mobile: 'only screen and (max-width: 479px)';
/* container 300px*/



body{
  color: #fff;
  background: #111215!important;
  line-height: 1.5;
  font-weight: 400;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

@import '../public/asset/scss/default/animations';
//@import '../public/asset/scss/default/extend';
@import '../public/asset/scss/default/mixins';
//@import '../public/asset/scss/default/reset';
//@import '../public/asset/scss/default/spacing';
//@import '../public/asset/scss/default/typography';
@import '../public/asset/scss/default/variables';



@import './component/intro/intro.scss';
@import './component/counter/counter.scss';
@import './component/service/services.scss';
@import './component/portfolios/portfolio.scss';

@import './component/portfolio/portfolio-modal.scss'; 
@import './component/portfolio/portfolio.scss';


/* [ HEADER ] */
/*================================================== */

/*** Desktop Navigation ***/

/*** Navigation ***/
.home-details-container {
  @media (max-width: 991px) {
    height: 100vh;
  }
}
.header {
  position: fixed;
  right: 30px;
  display: flex;
  align-items: center;
  top: 50%;
  opacity: 1;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  transform: translateY(-50%);
  z-index: 999;

  @media (max-width: 991px) {
    left: 0;
    right: auto;
    bottom: 0;
    top: auto;
    transform: none;
    width: 100%;
    display: block;
    background: #2b2a2a;
    padding: 8px;
    z-index: 10;

    ul.icon-menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      li.icon-box {
        margin: 0 !important;
        width: 45px !important;
        height: 45px !important;
        background: #444 !important;
        i {
          top: 14px;
        }
      }
      h2 {
        display: none;
      }
    }
  }
  @media (max-width: 360px) {
    ul.icon-menu {
      justify-content: space-between;
    }
  }
}

.header.hide-header {
  z-index: 0 !important;
  opacity: 0;
}

.header ul.icon-menu {
  margin: 0;
  padding: 0;
}

.header ul.icon-menu li.icon-box {
  width: 50px;
  height: 50px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.3s;
  margin: 20px 0;
  border-radius: 50%;
  background: #2b2a2a;
  cursor: pointer;
}
.header ul.icon-menu li.icon-box,
.portfolio-tab-list li {
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
}
.header ul.icon-menu li.icon-box i {
  color: #ddd;
  font-size: 19px;
  transition: 0.3s;
}

.header ul.icon-menu li.icon-box.react-tabs__tab--selected,
.header ul.icon-menu li.icon-box:hover,
.header ul.icon-menu li.icon-box.react-tabs__tab--selected i,
.header ul.icon-menu li.icon-box:hover i {
  color: #fff;
}

.header .icon-box h2 {
  font-size: 15px;
  font-weight: 500;
}

.header .icon-box i.fa {
  position: absolute;
  pointer-events: none;
}

.header .icon-menu i {
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 35px;
  top: 15px;
}

.header .icon-box h2 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  color: #fff;
  line-height: 50px;
  font-weight: 500;
  transition: all 0.3s;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 0 25px 0 30px;
  height: 50px;
  margin: 0;
}

.header .icon-box:hover h2 {
  opacity: 1;
  right: 27px;
  margin: 0;
  text-align: center;
  border-radius: 30px 0 0 30px;
  background: var(--color-secondary);
}






































/* [ GENERAL ] */
/*================================================== */

/*** Miscellaneous ***/

html {
  overflow-x: hidden;
  //height: 100%;
}
:root {
  --main-primary-color: #059dff;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  line-height: 1.6;
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  background-color: #111;
}

.poppins-font {
  font-family: "Poppins", sans-serif;
}

.open-sans-font {
  font-family: "Open Sans", sans-serif;
}
img {
  max-width: 100%;
}
hr.separator {
  border-top: 1px solid #252525;
  margin: 70px auto 55px;
  max-width: 40%;
}

.ft-wt-600 {
  font-weight: 600;
}

.mb-30 {
  margin-bottom: 30px;
}
.pb-50 {
  padding-bottom: 50px;
}
a,
button {
  cursor: pointer !important;
}
.no-transform {
  transform: none !important;
}

.custom-title {
  padding-bottom: 22px;
  font-size: 26px;
}


/*** Buttons ***/
.button {
  overflow: hidden;
  display: inline-block;
  line-height: 1.4;
  border-radius: 35px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  text-transform: uppercase;
  text-decoration: none !important;
  position: relative;
  z-index: 1;
  padding: 16px 70px 16px 35px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background-color: transparent;
  outline: none !important;
  cursor: pointer !important;
}

.button .button-text {
  position: relative;
  z-index: 2;
  color: #fff;
  transition: 0.3s ease-out all;
}

.button:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  transition: 0.4s ease-out all;
}

.button:hover .button-text {
  color: #ffffff;
}

.button:hover:before {
  transform: translateX(0);
  background-image: -webkit-linear-gradient(45deg, #059dff, #36c6f0);
}

.button-icon {
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: 0;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: #ffffff;
  border-radius: 50%;
}

/*** Page Title ***/

.title-section {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 80px 0;
}

.title-section h1 {
  font-size: 56px;
  font-weight: 900 !important;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.title-bg {
  font-size: 110px;
  left: 0;
  letter-spacing: 10px;
  line-height: 0.7;
  position: absolute;
  right: 0;
  top: 50%;
  text-transform: uppercase;
  font-weight: 800;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.07);
  @media (max-width: 767px) {
    font-size: 45px;
    transform: translateY(-20%);
  }
}

/* [ Pages ] */
/*================================================== */

/*** Homepage ***/

.home .color-block {
  position: fixed;
  height: 200%;
  width: 100%;
  transform: rotate(-15deg);
  left: -83%;
  top: -50%;
}

.home .bg img{
  width: auto;
  position: relative;
  animation: test 5s infinite ease;
  margin-left: 2em;
}

@keyframes test {
  0% {
    top: 0;
    transform: translate(0);
  }
  50% {
    top: 20%;
    transform: translate(9%);
  }
  100% {
    top: 0;
    transform: translate(0);
  }
}

.home .container-home {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.home .home-details{
  margin: 0;
}

.home .home-details > div {
  max-width: 550px;
  margin: 0 auto;
}

.home .home-details h1 {
  font-size: 2.75em;
  line-height: 62px;
  font-weight: 700;
  margin: 18px 0 10px;
  position: relative;
  padding-left: 70px;
  background: linear-gradient(to right, #059dff, #6549d5, #fb5343);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home .home-details h1:before {
  content: "";
  position: absolute;
  left: 0;
  top: 29px;
  height: 4px;
  width: 40px;
  border-radius: 10px;
  background: linear-gradient(to right, #fff, #fff, #fff) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home .home-details h1 span {
  color: #fff;
  display: block;
  background: linear-gradient(to right, #e8e8e8, #fcfcfc, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home .home-details h6 {
  color: #eee;
  font-size: 22px;
  font-weight: 400;
  display: block;
}

.home .home-details p {
  margin: 15px 0 28px;
  font-size: 1rem;
  line-height: 35px;
  color: #ffffff;
}




/*** Portfolio ***/
.portfolio .main-content {
  padding-bottom: 60px;
  margin-top: -15px;
}

/* Grid */

.portfolio .grid-gallery ul {
  list-style: none;
  margin: 0 -15px;
  padding: 0;
}

.portfolio .grid-gallery figure {
  margin: 0;
}

.portfolio .grid-gallery figure img {
  display: block;
  width: 100%;
  border-radius: 5px;
  transition: 0.3s;
}

.portfolio .grid-wrap {
  margin: 0 auto;
}

.portfolio .grid {
  margin: 0 auto;
}

.portfolio .grid li {
  width: 33.333333%;
  float: left;
  cursor: pointer;
  padding: 15px;
}

.portfolio .grid figure {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media screen and (min-width: 1300px) {
    min-height: 229px;
    background: #ddd;
  }
  @media screen and (max-width: 450px) {
    min-height: 215px;
  }
}

.portfolio .grid figure img {
  display: block;
  position: relative;
}

.portfolio .grid li a {
  overflow: hidden;
}

.portfolio .grid li figure div span {
  margin: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.portfolio .slideshow figure {
  width: 100%;
  height: 100%;
  background: #252525;
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0;
  img {
    border-radius: 6px;
  }
  @media screen and (min-width: 1350px) {
    img {
      background: #ddd;
      min-height: 370px;
    }
  }
  @media screen and (max-width: 450px) {
    img {
      min-height: 166px;
    }
  }
}

.portfolio .slideshow a {
  text-decoration: underline;
}

.portfolio .slideshow figcaption {
  margin-bottom: 15px;
}

.portfolio .slideshow figcaption h3 {
  text-transform: uppercase;
  padding: 10px 0 20px;
  font-weight: 700;
  text-align: center;
  font-size: 33px;
}

/* Embedded Videos */

.portfolio .slideshow .videocontainer,
.portfolio .slideshow .responsive-video {
  max-width: 100%;
  border-radius: 4px;
  display: block;
  object-fit: cover;
}

.portfolio .slideshow .videocontainer {
  position: relative;
  padding-bottom: 56.2%;
  padding-top: 0;
  height: 0;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  display: block;
  margin: 0;
  border-radius: 4px;
}

.portfolio .slideshow .videocontainer iframe,
.portfolio .slideshow .videocontainer object,
.portfolio .slideshow .videocontainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Carousel */

.portfolio .slideshow .carousel-indicators {
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px 0;
  margin: 0;
}

.portfolio .slideshow .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 7px;
  background-color: #fff;
  opacity: 1;
}

/* Navigation */

.portfolio .slideshow nav span {
  position: fixed;
  z-index: 1000;
  text-align: center;
  cursor: pointer;
  padding: 200px 30px;
  color: #fff;
}

.portfolio .slideshow nav span.nav-prev,
.portfolio .slideshow nav span.nav-next {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 41px;
  line-height: 58px;
}

.portfolio .slideshow nav span.nav-prev {
  left: 0;
}

.portfolio .slideshow nav span.nav-next {
  right: 0;
}

.portfolio .slideshow nav span.nav-close {
  top: 30px;
  right: 30px;
  padding: 0;
}

/*** Contact ***/
.contact .main-content {
  padding-bottom: 85px;
}
/* Left Side */

.contact .custom-span-contact {
  font-weight: 600;
  padding-left: 50px;
  line-height: 25px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.contact .custom-span-contact i {
  left: 0;
  top: 10px;
  font-size: 33px;
}

.contact .custom-span-contact i.fa-phone-square {
  font-size: 39px;
  top: 7px;
}

.contact .custom-span-contact span {
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 400;
  padding-bottom: 0;
}
.contact .custom-span-contact a {
  color: #fff;
  text-decoration: none;
  cursor: pointer !important;
}
body.light .contact .custom-span-contact a {
  color: #666;
}
.contact ul.social {
  margin-left: -5px;
  padding-top: 15px !important;
}

.contact ul.social li {
  display: inline-block;
}

.contact ul.social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  font-size: 17px;
  margin: 0 6px;
  background: #2b2a2a;
  border-radius: 50%;
}
/* Form */
.contact .contactform input[type="text"],
.contact .contactform input[type="email"] {
  border: 1px solid #111;
  width: 100%;
  background: #252525;
  color: #fff;
  padding: 11px 26px;
  border-radius: 30px;
  outline: none !important;
  transition: 0.3s;
}
.contact .contactform .form-group {
  margin-bottom: 30px;
}
.contact {
  .container {
    padding-bottom: 60px;
  }
  @media screen and (min-width: 1920px) {
    .container {
      padding-bottom: 214px;
    }
  }
  .contactform textarea {
    border: 1px solid #111;
    background: #252525;
    color: #fff;
    width: 100%;
    padding: 12px 26px;
    height: 200px;
    overflow: hidden;
    border-radius: 30px;
    outline: none !important;
    transition: 0.3s;
  }
}

.contact .contactform .form-message {
  margin: 24px 0;
}
.contactform {
  padding-left: 30px;
  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
}
.contact .contactform .output_message {
  display: block;
  color: #fff;
  height: 46px;
  line-height: 46px;
  border-radius: 30px;
}

.contact .contactform .output_message.success {
  background: #28a745;
}

.contact .contactform .output_message.success:before {
  font-family: FontAwesome;
  content: "\f14a";
  padding-right: 10px;
}

.contact .contactform .output_message.error {
  background: #dc3545;
}

.contact .contactform .output_message.error:before {
  font-family: FontAwesome;
  content: "\f071";
  padding-right: 10px;
}






/* [ RESPONSIVE DESIGN STYLES ] */

@media (min-width: 1351px) {
  .portfolio .container-portfolio .grid {
    text-align: left;
  }
}

@media (max-width: 1200px) {
  .home .home-details h1 {
    padding-left: 0;
  }
  .home .home-details h1:before {
    display: none;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .home .home-details h1 {
    font-size: 42px;
    line-height: 52px;
  }
}

@media (max-width: 1100px) and (min-width: 992px) {
  .home .home-details > div {
    max-width: 450px;
  }
}

@media (min-width: 1600px) {
  .home .home-details > div {
    max-width: 600px;
  }
}

@media (min-width: 992px) {
  .home .home-details-container {
    width: 100%;
    height: 100vh;
    z-index: 99999;
  }

  body.home {
    overflow: hidden;
  }
}

/* Mobile  Navigation */

@media (max-width: 991px) {
  body.home {
    padding: 25px;
  }

  body.home .main-container {
    padding: 30px 0 !important;
  }

  .home .home-details-container {
    margin: 0 auto;
  }

  .home .home-details {
    padding: 0;
    z-index: 9999;
  }

  .main-img-mobile {
    // border-radius: 50%;
    width: 270px;
    height: 270px;
    // border: 4px solid #252525;
    margin: 0 auto 25px;
    display: block;
  }

  .home .home-details h1 {
    font-size: 38px;
    line-height: 48px;
    margin: 29px 0 13px;
  }

  .home .home-details p {
    margin: 10px 0 23px;
    font-size: 15px;
    line-height: 30px;
  }

  #menuToggle {
    display: inline-block;
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 1111;
    user-select: none;
    padding: 19px 0 0 16px;
    width: 54px;
    height: 54px;
    border-radius: 5px;
    background: #252525;
  }

  #menuToggle input {
    display: flex;
    width: 54px;
    height: 54px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    top: 0;
    left: 0;
  }

  #menuToggle > span {
    display: flex;
    width: 23px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  #menuToggle > span:first-child {
    transform-origin: 0 0;
  }

  #menuToggle > span:nth-last-child(2) {
    transform-origin: 0 100%;
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(4px, 0);
    background: #fff;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(2px, 4px);
  }

  #menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 60px;
    background-color: #252525;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0 0;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }

  #menu li {
    position: relative;
    padding-left: 30px;
  }

  #menu li:after {
    position: absolute;
    content: "";
    height: 1px;
    width: calc(100% - 60px);
    background: #333;
    left: 30px;
  }

  #menu li:last-child:after {
    display: none;
  }

  #menu li.active a span {
    font-weight: 600;
  }

  #menu li a {
    color: #fff;
    text-transform: uppercase;
    transition-delay: 2s;
    font-size: 26px;
    display: block;
    text-decoration: none;
    padding: 14px 0;
    position: relative;
  }

  #menu li a span {
    position: absolute;
    left: 50px;
    font-weight: 400;
  }

  .portfolio .grid li {
    width: 50%;
  }

  .portfolio .slideshow {
    background: #252525;
  }

  .portfolio .slideshow nav span {
    padding: 0;
  }

  .portfolio .slideshow ul > li:after {
    display: none;
  }

  .portfolio .slideshow figure {
    padding: 30px 30px 30px;
    border-radius: 0;
    max-width: 720px;
    margin: 0 auto;
  }

  .portfolio .slideshow nav span.nav-prev,
  .portfolio .slideshow nav span.nav-next {
    top: 35px;
    width: 32px;
    height: 32px;
  }

  .portfolio .slideshow nav span.nav-next {
    right: 30px;
  }

  .portfolio .slideshow nav span.nav-prev {
    left: 30px;
  }

  .portfolio .slideshow nav span.nav-prev img,
  .portfolio .slideshow nav span.nav-next img {
    width: 32px;
    height: 32px;
  }

  .portfolio .slideshow nav span:before {
    display: none;
  }

  .portfolio .slideshow nav span.nav-close {
    top: 35px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 32px;
    height: 32px;
    display: block;
    line-height: 0;
  }

  .portfolio .slideshow nav span.nav-close img {
    width: 32px;
    height: 32px;
  }

  .portfolio .slideshow nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 102px;
    background: #252525;
    z-index: 1;
    border-bottom: 1px solid #333;
  }

  .contact .main-content {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .home .home-details p {
    font-size: 14px;
    line-height: 28px;
  }
}

@media (max-width: 576px) {
  .title-section.revealator-delay1 {
    transform: none !important;
    opacity: 1 !important;
  }

  #menuToggle {
    right: 10px;
    top: 4px;
    padding: 19px 0 0 13px;
    width: 49px;
    height: 49px;
  }

  .home #menuToggle {
    right: 25px;
    top: 25px;
    padding: 17px 0 0 13px;
  }

  #menu li {
    padding-left: 25px;
  }

  #menu li a {
    font-size: 18px;
  }

  #menu li:after {
    width: calc(100% - 50px);
    left: 25px;
  }

  #menu li a span {
    left: 35px;
  }

  .main-content {
    padding-top: 0;
  }

  .main-img-mobile {
    width: 230px;
    height: 230px;
  }
  .title-section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home .home-details h1 {
    font-size: 29px;
    line-height: 39px;
    margin: 18px 0 13px;
  }

  .home .home-details h6 {
    font-size: 19px;
  }

  .title-section h1 {
    font-size: 35px !important;
  }

  .custom-title {
    font-size: 21px;
  }

  .about .main-content {
    padding-bottom: 0;
  }

  .about .resume-box h5 {
    font-size: 17px;
  }

  .about .box-stats {
    padding: 15px 20px 20px 25px;
  }

  .about .c100 {
    transform: scale(0.8);
  }

  .about .box-stats.with-margin {
    margin-bottom: 25px;
  }

  .about .box-stats h3 {
    font-size: 40px;
  }

  .about .box-stats p {
    padding-left: 0;
  }

  .about .box-stats p:before {
    display: none;
  }

  .portfolio .main-content {
    padding-bottom: 10px;
    margin-top: -12px;
  }

  .portfolio .grid li {
    width: 100%;
  }

  .portfolio .main-content > .container {
    padding: 0 10px;
  }

  .portfolio .slideshow figure {
    padding: 25px 25px 25px;
  }

  .portfolio .slideshow figcaption h3 {
    font-size: 22px;
  }

  .portfolio .slideshow nav span.nav-prev,
  .portfolio .slideshow nav span.nav-next {
    top: 9px;
    width: 20px;
    height: 20px;
  }

  .portfolio .slideshow nav span.nav-next {
    right: 20px;
  }

  .portfolio .slideshow nav span.nav-prev {
    left: 20px;
  }

  .portfolio .slideshow nav span.nav-prev img,
  .portfolio .slideshow nav span.nav-next img {
    width: 20px;
    height: 20px;
  }

  .portfolio .slideshow nav span.nav-close {
    top: 21px;
    width: 20px;
    height: 20px;
  }

  .portfolio .slideshow nav span.nav-close img {
    width: 20px;
    height: 20px;
  }

  .portfolio .slideshow nav {
    height: 62px;
  }

  .blog .main-content {
    padding-bottom: 55px;
  }

  .blog-post article h1 {
    font-size: 20px;
    line-height: 1.3;
  }

  .separator.mt-1 {
    margin-top: 35px !important;
  }
}
.invalid-feedback {
  display: block;
}
.tab-panel_list {
  overflow: hidden;
  @media screen and (max-width: 991px) {
    padding-bottom: 60px;
  }
}

// ohters
// ul.row.grid,
// .row.pb-50 {
//   min-height: 700px;
// }
.tab-panel_list > div {
  position: relative;
  &.home {
    @media screen and (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    background-color: #222;
    z-index: 4;
  }
}
body.light .tab-panel_list > div {
  &::after {
    background-color: #eee;
  }
}

.tab-panel_list > div.react-tabs__tab-panel--selected:after {
  animation: anim 0.9s cubic-bezier(0.77, 0, 0.175, 1) both;
  right: 0px;
  bottom: 0px;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
.about-list {
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #333; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #555; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #333; /* creates padding around scroll thumb */
}

body.light::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

body.light::-webkit-scrollbar-thumb {
  background-color: #bbb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #fff; /* creates padding around scroll thumb */
}
button:focus {
  outline: none !important;
}

// bootstrap 5 customization
.container {
  max-width: 1200px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}

.pr-2 {
  padding-right: 0.5rem !important;
}
.none #showSwitcher {
  display: none;
}

// this css fixed with yellow color other color does not work
.portfolio .slideshow figcaption h3,
.portfolio .slideshow a,
.title-section h1 span,
.blog-post .meta i {
  color: var(--main-primary-color);
}
.box_inner.about {
  .pie,
  .c100 .bar,
  .c100.p51 .fill,
  .c100.p52 .fill,
  .c100.p53 .fill,
  .c100.p54 .fill,
  .c100.p55 .fill,
  .c100.p56 .fill,
  .c100.p57 .fill,
  .c100.p58 .fill,
  .c100.p59 .fill,
  .c100.p60 .fill,
  .c100.p61 .fill,
  .c100.p62 .fill,
  .c100.p63 .fill,
  .c100.p64 .fill,
  .c100.p65 .fill,
  .c100.p66 .fill,
  .c100.p67 .fill,
  .c100.p68 .fill,
  .c100.p69 .fill,
  .c100.p70 .fill,
  .c100.p71 .fill,
  .c100.p72 .fill,
  .c100.p73 .fill,
  .c100.p74 .fill,
  .c100.p75 .fill,
  .c100.p76 .fill,
  .c100.p77 .fill,
  .c100.p78 .fill,
  .c100.p79 .fill,
  .c100.p80 .fill,
  .c100.p81 .fill,
  .c100.p82 .fill,
  .c100.p83 .fill,
  .c100.p84 .fill,
  .c100.p85 .fill,
  .c100.p86 .fill,
  .c100.p87 .fill,
  .c100.p88 .fill,
  .c100.p89 .fill,
  .c100.p90 .fill,
  .c100.p91 .fill,
  .c100.p92 .fill,
  .c100.p93 .fill,
  .c100.p94 .fill,
  .c100.p95 .fill,
  .c100.p96 .fill,
  .c100.p97 .fill,
  .c100.p98 .fill,
  .c100.p99 .fill,
  .c100.p100 .fill {
    border: 0.08em solid var(--main-primary-color);
  }
}
.portfolio-tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px !important;
  @media (max-width: 767px) {
    justify-content: flex-start;
    padding-left: 0;
  }
  li {
    margin: 15px;
    margin-top: 0;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
    &:hover,
    &.react-tabs__tab--selected {
      color: var(--main-primary-color);
    }
  }
}

.portfolio.professional .grid li figure div span {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  z-index: 5;
  position: relative;
  transform: translateY(5px);
}
.portfolio.professional .grid li figure:hover div span {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.portfolio.professional .grid li figure div span {
  margin: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 48%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}
.portfolio.professional {
  .grid figure {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 5px;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      background: var(--main-primary-color);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
    }
    &:hover {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    @media screen and (min-width: 1300px) {
      min-height: 229px;
      background: #ddd;
    }
    @media screen and (max-width: 450px) {
      min-height: 215px;
    }
  }
}

.demo-sticker {
  background: #2b2a2a;
  position: fixed;
  right: 30px;
  top: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  @media (max-width: 991px) {
    right: 15px;
  }
  a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    padding: 10px;
    cursor: pointer !important;
    text-decoration: none;
  }
}


.fa-send::before, .fa-paper-plane::before {
  content: "\f1d8";
  position: relative;
  top: 18px;
  right: 13px;
}

.fa-arrow-right::before {
  position: relative;
  top: 18px;
  right: 13px;
}

#title-count{
  font-size: 1.3em;
}