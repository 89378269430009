
.main-content {
    background: #111215;
    position: relative;
    z-index: 3;
    overflow-x: hidden !important;
}

.circle-bg .gradient-circle {
    position: absolute;
    left: -250px;
    top: 250px;
    right: auto;
    bottom: auto;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: -webkit-linear-gradient(45deg, #059dff, #36c6f0);
    background-image: -o-linear-gradient(45deg, #059dff, #36c6f0);
    background-image: linear-gradient(45deg, #059dff, #36c6f0);
    opacity: .25;
    -webkit-filter: blur(100px);
    filter: blur(100px);
}

.circle-bg .gradient-circle.two {
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    background-image: -webkit-linear-gradient(45deg, #fb5343, #6549d5);
    background-image: -o-linear-gradient(45deg, #fb5343, #6549d5);
    background-image: linear-gradient(45deg, #fb5343, #6549d5);
}

.color-font {
    //background: -webkit-gradient(linear, left top, right top, from(rgba(18,194,233,1) 20%), color-stop(rgba(0,212,255,1) 75%), to(rgba(2,0,36,1) 100%));
    //background: -webkit-linear-gradient(left, rgba(18,194,233,1) 20%, rgba(0,212,255,1) 75%, rgba(2,0,36,1) 100%);
    //background: -o-linear-gradient(left,  rgba(18,194,233,1) 20%,  rgba(0,212,255,1) 75%, rgba(2,0,36,1) 100%);
    //background: linear-gradient(to right, rgba(0,212,255,1) 75%, rgba(0,212,255,1) 75%, rgba(2,0,36,1) 100%);
    //background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    background: linear-gradient(to right, #059dff, #6549d5, #fb5343);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .particles {
    min-height: 100vh;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  .particles .cont h1 {
    font-size: 90px;
    font-weight: 800;
  }

  div#particles-js canvas {
    position: unset !important;
    height: 100% !important;
    width: 100% !important;
  }

  
canvas.tsparticles-canvas-el {
  position: absolute !important;
}