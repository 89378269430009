/*-------------------------
    Portfolio Area 
---------------------------*/

.transparent_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.im_portfolio {
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: 10px;
    transition: transform .65s cubic-bezier(.05,.2,.1,1),box-shadow .65s cubic-bezier(.05,.2,.1,1);
}



.thumbnail_inner {
    .thumbnail {
        height: 30vh;
        background-repeat: no-repeat!important;
        background-size: cover!important;
        background-position: center!important;
            a {
                display: block;
                img {
                    transition: transform 1s ease,opacity 0.5s ease 0.25s;
                    border-radius: 10px;
                    width: 100%;
                }
        }
    }
}

.content {
    .inner {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 30px 30px;
        z-index: 2;
        width: 100%;
        @media #{$sm-layout} {
            padding: 15px 15px;
        }

        .portfolio_heading {
            transition: opacity .65s cubic-bezier(.05,.2,.1,1),transform .65s cubic-bezier(.05,.2,.1,1);
            .category_list {
                margin: -5px;
                margin-bottom: 5px;
                a {
                    color: #ffffff;
                    opacity: 0.7;
                    display: inline-block;
                    margin: 5px;
                    font-size: 16px;
                    font-weight: 300;
                    letter-spacing: 0.5px;
                }
            }
            .title {
                color: #ffffff;
                font-size: 30px;
                a  {
                    color: #ffffff;
                }
                @media #{$lg-layout} {
                    font-size: 24px;
                    margin-bottom: 0;
                }
                @media #{$md-layout} {
                    font-size: 24px;
                    margin-bottom: 0;
                }
                @media #{$sm-layout} {
                    font-size: 22px;
                    margin-bottom: 0;
                }
            }
        }
        .portfolio_hover {
            opacity: 0;
            position: absolute;
            bottom: 35px;
            margin-top: 10px;
            transform: translateY(20px);
            line-height: 1.5em;
            max-width: 80%;
            transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1),transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
            @media #{$sm-layout} {
                bottom: 15px;
            }
            p {
                color: #ffffff;
                opacity: 0.7;
            }
        }

    }
}
.im_portfolio {
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: 10px;
    transition: transform .65s cubic-bezier(.05,.2,.1,1),box-shadow .65s cubic-bezier(.05,.2,.1,1);

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: '';
        background: linear-gradient(to bottom,rgba(15,15,15,0),rgba(15,15,15,.75) 100%);
        transition: opacity .65s cubic-bezier(.05,.2,.1,1);
        cursor: pointer;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: '';
        background: linear-gradient(to bottom,rgba(0,0,0,0), #059dff 100%);
        transition: opacity .65s cubic-bezier(.05,.2,.1,1);
        cursor: pointer;
        opacity: 0;
    }

    .thumbnail_inner {
        .thumbnail {
            a {
                display: block;
                img {
                    transition: transform 1s ease,opacity 0.5s ease 0.25s;
                    border-radius: 10px;
                    width: 100%;
                }
            }
        }
    }

    .content {
        .inner {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 30px 30px;
            z-index: 2;
            width: 100%;
            @media #{$sm-layout} {
                padding: 15px 15px;
            }

            .portfolio_heading {
                transition: opacity .65s cubic-bezier(.05,.2,.1,1),transform .65s cubic-bezier(.05,.2,.1,1);
                .category_list {
                    margin: -5px;
                    margin-bottom: 5px;
                    a {
                        color: #ffffff;
                        opacity: 0.7;
                        display: inline-block;
                        margin: 5px;
                        font-size: 16px;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                    }
                }
                .title {
                    color: #ffffff;
                    font-size: 30px;
                    a  {
                        color: #ffffff;
                    }
                    @media #{$lg-layout} {
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                    @media #{$md-layout} {
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                    @media #{$sm-layout} {
                        font-size: 22px;
                        margin-bottom: 0;
                    }
                }
            }
            .portfolio_hover {
                opacity: 0;
                position: absolute;
                bottom: 35px;
                margin-top: 10px;
                transform: translateY(20px);
                line-height: 1.5em;
                max-width: 80%;
                transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1),transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
                @media #{$sm-layout} {
                    bottom: 15px;
                }
                p {
                    color: #ffffff;
                    opacity: 0.7;
                }
            }

        }
    }
    
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 25px 55px  #059dff63;
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }

        .thumbnail_inner {
            .thumbnail {
                a {
                    img {
                        transform: scale(1.1);
                        transition: all 9s cubic-bezier(.1,.2,7,1);
                    }
                }
            }
        }

        .content {
            .inner {
                .portfolio_heading {
                    transform: translateY(-62px);
                    transition: opacity .65s cubic-bezier(.05,.2,.1,1),transform .65s cubic-bezier(.05,.2,.1,1);
                    
                }
                .portfolio_hover {
                    transform: translateY(0);
                    opacity: 1;
                    transition: opacity .65s cubic-bezier(.05,.2,.1,1) 0.15s,transform .65s cubic-bezier(.05,.2,.1,1) 0.15s;
                }

            }
        }
    }

}
.fourSection {
z-index: 99;
position: relative;
margin-bottom: 5em;
}