
// Counter Style One
.counterup_style--1 {
  text-align: center;
  margin-top: 40px;

  h5 {
      &.counter {
          font-size: 72px;
          line-height: 80px;
          display: inline-block;
          margin-bottom: 17px;
          position: relative;
          display: inline-block;
          color: var(--color-primary);
          margin-left: -20px;

         
          @media #{$sm-layout} {
              font-size: 45px;
              line-height: 56px;
              margin-bottom: 5px;
              margin-left: -11px;
          }

          &::after {
              right: -26px;
              position: absolute;
              top: -17px;
              font-size: 40px;
              content: "+";
          }
      }
  }

  p {
      &.description {
          font-size: 18px;
          line-height: 30px;
          padding: 0 5%;
          color: rgba(29, 29, 36, 0.75);

          @media #{$md-layout} {
              font-size: 16px;
              line-height: 28px;
              padding: 0;
          }

          @media #{$sm-layout} {
              font-size: 16px;
              line-height: 28px;
              padding: 0;
          }
      }
  }
}




/*----------------------------
  Counterup New Style  
------------------------------*/
.bg_color--1 {
  .im_counterup {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  }
}


.im_counterup {
  height: 280px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 10px;

  .inner {
      .icon {
          height: 50px;
          width: 50px;
          margin: 0 auto;
          line-height: 47px;
          border-radius: 100%;
          svg {
              stroke-width: 1;
              font-size: 45px;
          }
      }
      .counter {
          font-size: 54px;
          font-weight: 600;
          line-height: 62px;
          margin-top: 20px;
          position: relative;
          display: inline-block;
          margin-bottom: 7px;
          @media #{$md-layout} {
              font-size: 44px;
              font-weight: 600;
              line-height: 52px;
          }
          @media #{$sm-layout} {
              font-size: 44px;
              font-weight: 600;
              line-height: 52px;
          }
          &::after {
              content: "+";
          }
      }
      .description {
          font-size: 18px;
          line-height: 28px;
      }
  }
}



.im_single_counterup {
  .im_counterup {
      .inner {
          .icon {
              // background-color: rgba(51,125,255,0.3);
              svg {
                  color: var(--color-primary);
              }
          }
      }
  }

  &:nth-child(2) {
      .im_counterup {
          .inner {
              .icon {
                  // background-color: rgba(250,94,93,0.3);
                  svg {
                      color: var(--color-secondary);
                  }
              }
          }
      }
  }

  &:nth-child(3) {
      .im_counterup {
          .inner {
              .icon {
                  // background-color: rgba(249,141,94,0.3);
                  svg {
                      color: var(--color-warning);
                  }
              }
          }
      }
  }

  &:nth-child(4) {
      .im_counterup {
          .inner {
              .icon {
                  // background-color: rgba(118,206,68,0.3);
                  svg {
                      color: var(--color-success);
                  }
              }
          }
      }
  }

}
.threeSection {
    z-index: 3;
    position: relative;
    padding-bottom: 5em;
    padding-top: 3em;
    background: #101010;
}

.im_counterup{
    background: #0c0c0c;
    color: #ffffff;
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}

.im_counterup:hover{
box-shadow: 0px 2px 70px 0px #059dff63;
transform: translateY(-5px);
}


.drop {
    position: relative;
    width: 120px;
    height: 120px;
    box-shadow: inset 1px 1px 1px rgba(19, 19, 19, 0.322), 14px 19px 9px rgba(19, 19, 19, 0.322), 5px 2px 2px rgba(19, 19, 19, 0.322), inset -1px 1px 9px #059dff63;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 35px;
    transition: all ease-in-out 1.8s;
  }

  .drop .icon_text, .drop:hover #img, .drop:active #img, .drop:hover .icon, .drop:active .icon {
    display: none;
    transition: all ease-in-out 1.8s;
  }

  .drop .text_drop, .drop .image_icon .text_drop {
    display: none;
    transition: all ease-in-out 1.8s;
  }


  .image_icon{
    height: 50%;
  }

  .icon-tech{
    color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
  }

  .drop:hover .text_drop, .drop:active .text_drop, .drop:hover .image_icon .text_drop, .drop:active .image_icon .text_drop {
    display: block;
    color: var(--main-primary-color);
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: -2px;
    text-align: center;
    transition: all ease-in-out 1.8s;
  }

  .drop:hover, .drop:active {
    box-shadow: inset 3px 3px 3px 3px rgba(31, 31, 31, 0.9), 1px 1px 9px rgba(40, 40, 40, 0.9);
    background-color: rgb(255, 255, 255);
    transition: all ease-in-out 1.8s;
    .text_drop {
        display: block;
      }

      .image_icon {
        height: 20%;
      }

    .icon-tech{
        display: none;
    }
  }

  @media screen and (min-width: 870px) and (max-width: 1600px) {
    .icon_exp {
        grid-template-columns: repeat(4,1fr)!important;
        gap: 0.8em!important;
      }
  }

  @media screen and (min-width: 640px) and (max-width: 869px) {
    .icon_exp {
        grid-template-columns: repeat(3,1fr)!important;
        gap: 0.5em!important;
      }
  }

  @media screen and (min-width: 390px) and (max-width: 639px) {
    .icon_exp {
        grid-template-columns: repeat(2,1fr)!important;
        gap: 0.5em!important;
      }
  }

  @media screen and (min-width: 90px) and (max-width: 389px) {
    .icon_exp {
        grid-template-columns: repeat(1,1fr)!important;
        gap: 0.5em!important;
      }
  }

  .icon_exp {
    grid-template-columns: repeat(5,1fr);
    display: grid;
    gap: 1em;
  }



